import { NgModule } from '@angular/core';
import { CardModule } from 'primeng/card';
import { DefaultAuthenticationPageComponent } from './default-authentication-page/default-authentication-page.component';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';





@NgModule({
    declarations: [
        DefaultAuthenticationPageComponent
    ],
    imports: [
        RouterModule,
        CardModule

    ],
    providers: [],
    exports: [
        DefaultAuthenticationPageComponent
    ],
    entryComponents: []
})






export class LayoutModule {

}