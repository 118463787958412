import { Component, OnInit } from '@angular/core';

export class Statistic {
  id?: number;
  type?: string;
  icon?: string;
  value?: any[];
}


@Component({
  selector: 'app-statistics-page',
  templateUrl: './statistics-page.component.html',
  styleUrls: ['./statistics-page.component.css']
})
export class StatisticsPageComponent implements OnInit {

  statisticsItemsRowOne: Statistic[];
  statisticsItemsRowTwo: Statistic[];


  constructor() { }

  ngOnInit() {

    this.statisticsItemsRowOne = [
      {
        id: 1,
        type: "STATISTIQUES GLOBALES",
        icon: "pi pi-globe",
      },
      {
        id: 2,
        type: "Contrôle de vie AT",
        icon: "pi pi-user",
      },
      {
        id: 3,
        type: "Contrôle de vie FDT",
        icon: "pi pi-user",
      }
    ];

    this.statisticsItemsRowTwo = [
      {
        id: 4,
        type: " Accusé de réception Dossier",
        icon: "pi pi-file",
      },
      {
        id: 5,
        type: " Notification Paiement",
        icon: "pi pi-dollar",
      },
    ]



  }

}
