import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-authentication-page',
  templateUrl: './default-authentication-page.component.html',
  styleUrls: ['./default-authentication-page.component.css']
})
export class DefaultAuthenticationPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
