import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './shared/header/header.component';
import { CampaignManagementTableComponent } from './shared/widgets/campaign-management-table/campaign-management-table.component';
import { CampaignManagementPageComponent } from './modules/campaign-management-page/campaign-management-page.component';
import { NotificationProcessPageComponent } from './modules/notification-process-page/notification-process-page.component';
import { StatisticsPageComponent } from './modules/statistics-page/statistics-page.component';
import { LoginComponent } from './modules/login/login.component';
import { DefaultComponent } from './layouts/default/default.component';
import { DefaultAuthenticationPageComponent } from './layouts/default-authentication-page/default-authentication-page.component';
import { ResetEmailPasswordComponent } from './modules/reset-email-password/reset-email-password.component';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { StatisticsCardComponent } from './shared/widgets/statistics-card/statistics-card.component';
import { UserManagementPageComponent } from './modules/user-management-page/user-management-page.component';


const routes: Routes = [
    {
        path: "",
        component: DefaultAuthenticationPageComponent,
        children: [
            {
                path: "",
                component: LoginComponent
            },
            {
                path: "reset-email-password",
                component: ResetEmailPasswordComponent,
            },
            {
                path: "change-password",
                component: ChangePasswordComponent,
            }
        ]
    },
    {
        path: "dashboard",
        component: DefaultComponent,
        children: [

            {
                path: "campagne",
                component: CampaignManagementPageComponent
            },
            {
                path: "notification",
                component: NotificationProcessPageComponent
            },
            {
                path: "statistic",
                component: StatisticsPageComponent
            },
            {
                path: "administration",
                component: UserManagementPageComponent
            }
        ]
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
