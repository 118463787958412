import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { DefaultComponent } from './layouts/default/default.component';
import { CampaignManagementPageComponent } from './modules/campaign-management-page/campaign-management-page.component';
import { NotificationProcessPageComponent } from './modules/notification-process-page/notification-process-page.component';
import { StatisticsPageComponent } from './modules/statistics-page/statistics-page.component';
import { LoginComponent } from './modules/login/login.component';
import { LayoutModule } from './layouts/layout.module';
import { ButtonModule } from 'primeng/button';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { ResetEmailPasswordComponent } from './modules/reset-email-password/reset-email-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserManagementPageComponent } from './modules/user-management-page/user-management-page.component';




@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    CampaignManagementPageComponent,
    NotificationProcessPageComponent,
    StatisticsPageComponent,
    LoginComponent,
    ChangePasswordComponent,
    ResetEmailPasswordComponent,
    UserManagementPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    LayoutModule,
    CommonModule,
    ButtonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
