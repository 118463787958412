import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

export class NavItems {
  label: string;
  link: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  private navNenuItems: NavItems[];

  constructor(private router: Router) { }

  ngOnInit() {



    this.navNenuItems = [
      {
        label: 'Campagnes',
        link: '/dashboard/campagne',
      },
      {
        label: 'Processus de notification',
        link: '/dashboard/notification',
      },
      {
        label: 'Statistiques',
        link: '/dashboard/statistic',
      },
      {
        label: 'Administration',
        link: '/dashboard/administration',
      }
    ];


  }

  logout() {
    location.replace("/");
  }
}