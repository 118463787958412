import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { async } from '@angular/core/testing';

export class Notification {
  id: number;
  scenarioName: string;
  description: string;
  callTime: string;
  sda: string;
  campaignType: string;
  action: string;
  statut?: boolean;
}


const NotificationFixtures: Notification[] = [
  {
    id: 1,
    scenarioName: "CVI - Contrôle de vie AT",
    description: "Informer la population cible qui figure dans l'échantillon choisi par l'Entité Suivi de Paiement de la nécessité du dépôt de son CVI pour lui éviter la suspension de sa rente",
    callTime: "9:00",
    sda: "0522132520",
    campaignType: "Sur demande",
    action: "onDemand",
  },
  {
    id: 2,
    scenarioName: "CVI - Contrôle de vie FDT",
    description: "Informer la population cible qui figure dans l'échantillon choisi par l'Entité Suivi de Paiement de la nécessité du dépôt de son CVI pour lui éviter la suspension de sa rente",
    callTime: "9:00",
    sda: "0522132521",
    campaignType: "Sur demande",
    action: "onDemand",
  },
  {
    id: 3,
    scenarioName: "PPA - Accusé de réception Dossier",
    description: "Informer les clients RCAR ayant déposé leurs demandes de liquidation que leur dossier a bien été reçu et annoncer des délais approximatifs pour le paiment",
    callTime: "10:00",
    sda: "0522132523",
    campaignType: "Récurrente",
    action: "recurrent",
    statut: false,
  },
  {
    id: 4,
    scenarioName: "PPA - Notification Paiement",
    description: "Informer les clients du paiement de premiers arrérages",
    callTime: "11:00",
    sda: "0522132522",
    campaignType: "Récurrente",
    action: "recurrent",
    statut: true,
  }

]

const colsTableNotification: any[] = [
  { field: 'id', header: 'Id', width: "30px" },
  { field: 'scenarioName', header: 'Nom', width: "150px" },
  { field: 'description', header: 'Descriptif' },
  { field: 'callTime', header: 'Heure d\'appel', width: "100px" },
  { field: 'sda', header: 'Numéro affiché', width: "150px" },
  { field: 'campaignType', header: 'Type de campagne', width: "100px" },
  { field: 'action', header: 'Action', width: "150px" }
]

const callTimeFixtures: SelectItem[] = [
  { label: '09:00', value: "09:00" },
  { label: '10:00', value: "10:00" },
  { label: '11:00', value: "11:00" }
]

const SDAFixtures: SelectItem[] = [
  { label: "0522132521", value: "0522132521" },
  { label: "0522132520", value: "0522132520" },
  { label: "0522132522", value: "0522132522" },
  { label: "0522132523", value: "0522132523" }
]

@Component({
  selector: 'app-notification-process-table',
  templateUrl: './notification-process-table.component.html',
  styleUrls: ['./notification-process-table.component.css']
})
export class NotificationProcessTableComponent implements OnInit {


  notifications: Notification[];
  cols: any[];

  callTime: SelectItem[];

  SDA: SelectItem[];

  constructor(private messageService: MessageService) { }

  ngOnInit() {

    this.cols = colsTableNotification;

    this.notifications = NotificationFixtures;
    this.callTime = callTimeFixtures;
    this.SDA = SDAFixtures;


  }


  showConfirm() {
    this.messageService.clear();
    this.messageService.add({ key: 'confirm', sticky: true, severity: 'warn', summary: 'êtes-vous sûr ?', detail: 'Voulez-vous vraiment créer la campagne ?' });
  }


  onReject() {
    this.messageService.clear();
  }

  onConfirm() {

    (async () => {
      this.messageService.clear();
      await this.delay(500);
      this.messageService.add({ key: 'success', sticky: true, severity: 'warn', summary: 'En création!', detail: 'Vous receverez un mail dés que la campagne est prête pour lancement' })
    })();


  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


}
