import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { SelectItem, MenuItem, MessageService } from 'primeng/api';
import { NotificationProcessTableComponent } from '../notification-process-table/notification-process-table.component';


export class User {
  id: number;
  fullName: string;
  email: string;
  notificationList: string;
  role: string;
  statut: boolean;
}


const userFixtures: User[] = [
  { id: 1, fullName: 'Ayoub Assila', email: 'ayoub.assila@gomobile.com', notificationList: 'CVI - Contrôle de vie AT, CVI - Contrôle de vie FDT', role: 'Utilisateur', statut: true },
  { id: 2, fullName: 'AbdelHamid Rahmaoui', email: 'abdelhamid.rahmaoui@gomobile.com', notificationList: 'PPA - Accusé de réception Dossier, PPA - Notification Paiement', role: 'Utilisateur', statut: false },
  { id: 4, fullName: 'Sami Elfakir', email: 'sami.elfakir@gomobile.com', notificationList: 'CVI - Contrôle de vie AT, CVI - Contrôle de vie FDT, PPA - Accusé de réception Dossier, PPA - Notification Paiement', role: 'Administrateur', statut: true },
]


const rolesFixture: SelectItem[] = [
  { label: 'Administrateur', value: 'Administrateur' },
  { label: 'Utilisateur', value: 'Utilisateur' }
]

const notificationFixture: SelectItem[] = [
  { label: 'CVI - AT', value: 'CVI - AT' },
  { label: 'CVI - FDT', value: 'CVI - FDT' },
  { label: 'PPA - ARD', value: 'PPA - ARD' },
  { label: 'PPA - NP', value: 'PPA - NP' }
]

@Component({
  selector: 'app-user-management-table',
  templateUrl: './user-management-table.component.html',
  styleUrls: ['./user-management-table.component.css']
})
export class UserManagementTableComponent implements OnInit {

  users: User[];
  cols: any[];

  roles: SelectItem[];
  notification: SelectItem[];

  dialogAddUser: boolean = false;
  dialogUpdateUser: boolean = false;
  stepsItems: MenuItem[];
  stepsItems2: MenuItem[];
  activeIndex: number = 0;


  constructor() { }

  ngOnInit() {
    this.users = userFixtures;

    this.cols = [
      { field: 'id', header: 'Id', width: '30px' },
      { field: 'fullName', header: 'Nom Complet', width: '150px' },
      { field: 'email', header: 'Email', width: '250px' },
      { field: 'notificationList', header: 'Processus de notification affectés', width: '400px' },
      { field: 'role', header: 'Role', width: '200px' },
      { field: 'action', header: 'Action', width: '150px' },

    ]

    this.roles = rolesFixture;
    this.notification = notificationFixture;

    this.stepsItems = [
      {
        label: 'Vérification de l\'email',
      },
      {
        label: 'Création de l\'utilisateur',
      }
    ]

    this.stepsItems2 = [
      {
        label: 'Modification de l\'email',
      },
      {
        label: 'Modification des paramètre',
      }
    ]

  }


  showDialogAddUser() {
    this.dialogAddUser = true;
  }

  showDialogUpdateUser() {
    this.dialogUpdateUser = true;
  }

  checkEmail() {
    this.dialogUpdateUser = false;
    this.dialogAddUser = false;
  }

  returnToEmail() {
    this.activeIndex = 0;
  }


}
