import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-email-password',
  templateUrl: './reset-email-password.component.html',
  styleUrls: ['./reset-email-password.component.css']
})
export class ResetEmailPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
