import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { SelectItem, MessageService } from 'primeng/api';


export class Campaign {
  id: number;
  creation: string;
  notificationType: string;
  releaseDate: string;
  numberOfClients: number;
  dropoutRate: string;
  listeningRate: string;
  interactionRate: string;
  campaignStatus: string;
  severity?: string;
  actions: string;
}

const campaignFixtures: Campaign[] = [
  { id: 1, creation: '11/06/2020 11:00', notificationType: 'CVI - Contrôle de vie AT', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '-', listeningRate: '-', interactionRate: '-', campaignStatus: 'A valider', actions: '2' },
  { id: 2, creation: '08/06/2020 11:00', notificationType: 'PPA - Accusé de réception Dossier', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '70%', listeningRate: '95%', interactionRate: '70%', campaignStatus: 'Terminée', actions: '1' },
  { id: 3, creation: '06/06/2020 11:00', notificationType: 'PPA - Notification Paiement', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '-', listeningRate: '-', interactionRate: '-', campaignStatus: 'En cours', actions: '0' },
  { id: 4, creation: '04/06/2020 11:00', notificationType: 'PPA - Accusé de réception Dossier', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '-', listeningRate: '-', interactionRate: '-', campaignStatus: 'Annulée', actions: '0' },
  { id: 5, creation: '02/06/2020 11:00', notificationType: 'CVI - Contrôle de vie AT', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '65%', listeningRate: '100%', interactionRate: '50%', campaignStatus: 'Terminée', actions: '1' },
  { id: 6, creation: '01/06/2020 11:00', notificationType: 'CVI - Contrôle de vie FDT', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '-', listeningRate: '-', interactionRate: '-', campaignStatus: 'Annulée', actions: '0' },
  { id: 7, creation: '01/06/2020 11:00', notificationType: 'CVI - Contrôle de vie FDT', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '-', listeningRate: '-', interactionRate: '-', campaignStatus: 'Annulée', actions: '0' },
  { id: 8, creation: '01/06/2020 11:00', notificationType: 'CVI - Contrôle de vie FDT', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '-', listeningRate: '-', interactionRate: '-', campaignStatus: 'Annulée', actions: '0' },
  { id: 9, creation: '01/06/2020 11:00', notificationType: 'CVI - Contrôle de vie FDT', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '-', listeningRate: '-', interactionRate: '-', campaignStatus: 'Annulée', actions: '0' },
  { id: 10, creation: '01/06/2020 11:00', notificationType: 'CVI - Contrôle de vie FDT', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '-', listeningRate: '-', interactionRate: '-', campaignStatus: 'Annulée', actions: '0' },
  { id: 11, creation: '01/06/2020 11:00', notificationType: 'CVI - Contrôle de vie FDT', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '-', listeningRate: '-', interactionRate: '-', campaignStatus: 'Annulée', actions: '0' },
  { id: 12, creation: '01/06/2020 11:00', notificationType: 'CVI - Contrôle de vie FDT', releaseDate: '02/01/2020 11:30', numberOfClients: 50, dropoutRate: '-', listeningRate: '-', interactionRate: '-', campaignStatus: 'Annulée', actions: '0' },
]

const notificationFixtures: SelectItem[] = [
  { label: 'Tous les processus', value: 'Tous les processus' },
  { label: 'CVI - Contrôle de vie AT', value: 'CVI - Contrôle de vie AT' },
  { label: 'CVI - Contrôle de vie FDT', value: 'CVI - Contrôle de vie FDT' },
  { label: 'PPA - Accusé de réception Dossier', value: 'PPA - Accusé de réception Dossier' },
  { label: 'PPA - Notification Paiement', value: 'PPA - Notification Paiement' },
]

@Component({
  selector: 'app-campaign-management-table',
  templateUrl: './campaign-management-table.component.html',
  styleUrls: ['./campaign-management-table.component.css']
})
export class CampaignManagementTableComponent implements OnInit {

  campaigns: Campaign[];
  cols: any[];
  first = 0;
  rows = 6;

  @ViewChild('data', { static: false }) table: Table;

  notifications: SelectItem[];

  constructor(private messageService: MessageService) { }

  ngOnInit() {

    this.cols = [
      { field: 'id', header: 'Id' },
      { field: 'creation', header: 'Création' },
      { field: 'notificationType', header: 'Type de notification' },
      { field: 'releaseDate', header: 'Date de lancement' },
      { field: 'numberOfClients', header: 'Nombre de clients' },
      { field: 'dropoutRate', header: 'Tx. de décroché' },
      { field: 'listeningRate', header: 'Tx. d\'écoute' },
      { field: 'interactionRate', header: 'Tx. d\'interaction	' },
      { field: 'campaignStatus', header: 'Statut campagne' },
      { field: 'actions', header: 'Actions' }

    ]

    this.campaigns = campaignFixtures;

    this.campaigns.forEach(campaign => {
      if (campaign.campaignStatus == "A valider")
        campaign.severity = "warn";
      else if (campaign.campaignStatus == "Terminée")
        campaign.severity = "success";
      else if (campaign.campaignStatus == "En cours")
        campaign.severity = "info"
      else if (campaign.campaignStatus == "Annulée")
        campaign.severity = "error"
    });


    this.notifications = notificationFixtures;

  }



  showConfirm() {
    this.messageService.clear();
    this.messageService.add({ key: 'confirm', sticky: true, severity: 'warn', summary: 'êtes-vous sûr ?', detail: 'Voulez-vous vraiment lancer la campagne ?' });
  }


  onReject() {
    this.messageService.clear();
  }

}
