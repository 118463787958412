import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { BrowserModule } from '@angular/platform-browser';
import { CardModule } from 'primeng/card';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { FooterComponent } from './footer/footer.component';
import { CampaignManagementTableComponent } from './widgets/campaign-management-table/campaign-management-table.component';
import { NotificationProcessTableComponent } from './widgets/notification-process-table/notification-process-table.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StatisticsCardComponent } from './widgets/statistics-card/statistics-card.component';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { UserManagementTableComponent } from './widgets/user-management-table/user-management-table.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { CheckboxModule } from 'primeng/checkbox';





@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        CampaignManagementTableComponent,
        NotificationProcessTableComponent,
        StatisticsCardComponent,
        UserManagementTableComponent
    ],
    imports: [
        MenuModule,
        ButtonModule,
        BrowserModule,
        TableModule,
        MessageModule,
        DropdownModule,
        CardModule,
        InputSwitchModule,
        FormsModule,
        CalendarModule,
        ToastModule,
        SelectButtonModule,
        DialogModule,
        StepsModule,
        CheckboxModule

    ],
    providers: [
        MessageService
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        CampaignManagementTableComponent,
        NotificationProcessTableComponent,
        StatisticsCardComponent,
        UserManagementTableComponent

    ],
    entryComponents: []
})






export class SharedModule {

}